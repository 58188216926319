@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* app.css */
body {
  background: linear-gradient( #3b82f6, #6366f1);
  background-size: cover;
}

.scrollable-box {
  max-height: 400px; /* Adjust the height as needed */
  overflow: auto;
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.card-container {
  overflow-y: auto;
  max-height: 300px; /* Adjust the maximum height as needed */
}

.card-container::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}


/* App.css */

/* Add your existing styles here */

/* Media queries for mobile devices */
@media (max-width: 767px) {
  .container {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .bg-white {
    padding: 2rem;
  }

  .text-2xl {
    font-size: 1.5rem;
  }

  .px-4,
  .px-6,
  .px-8 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .w-full {
    width: 100%;
  }

  .px-4,
  .py-3 {
    padding: 0.75rem;
  }

  .text-gray-800 {
    color: #333;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .text-red-500 {
    color: #dc3545;
  }

  .text-gray-500 {
    color: #888;
  }

  .border {
    border: 1px solid #ddd;
  }

  .p-4 {
    padding: 1rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .bg-blue-500 {
    background-color: #3490dc;
  }

  .text-white {
    color: #fff;
  }

  .px-4,
  .py-2 {
    padding: 0.5rem;
  }
}


 .flex-1::-webkit-scrollbar {
            width: 0.4rem;
            background-color: transparent;}

@media (min-width: 640px) {
  /* Small screens (e.g. mobile) */
  .dashboard-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  /* Medium screens (e.g. tablet) */
  .dashboard-content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  /* Large screens (e.g. laptop) */
  .dashboard-content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  /* Extra large screens (e.g. desktop) */
  .dashboard-content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
